import { FC } from 'react';
import IconProps from './IconProps';

const FileIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24951 10.0219C8.83011 10.0219 8.49013 10.3618 8.49013 10.7812C8.49013 11.2006 8.83011 11.5406 9.24951 11.5406V10.0219ZM13.937 11.5406C14.3564 11.5406 14.6964 11.2006 14.6964 10.7812C14.6964 10.3618 14.3564 10.0219 13.937 10.0219V11.5406ZM9.24951 13.1469C8.83011 13.1469 8.49013 13.4868 8.49013 13.9062C8.49013 14.3256 8.83011 14.6656 9.24951 14.6656V13.1469ZM13.937 14.6656C14.3564 14.6656 14.6964 14.3256 14.6964 13.9062C14.6964 13.4868 14.3564 13.1469 13.937 13.1469V14.6656ZM12.6058 3.97882L12.0688 4.51574L12.0688 4.51578L12.6058 3.97882ZM16.8356 8.20868L17.3726 7.67176L17.3726 7.67172L16.8356 8.20868ZM9.24951 11.5406H13.937V10.0219H9.24951V11.5406ZM9.24951 14.6656H13.937V13.1469H9.24951V14.6656ZM15.502 17.0531H7.68945V18.5719H15.502V17.0531ZM7.68945 17.0531C7.24589 17.0531 6.88633 16.6936 6.88633 16.25H5.36758C5.36758 17.5324 6.40712 18.5719 7.68945 18.5719V17.0531ZM6.88633 16.25V5.3125H5.36758V16.25H6.88633ZM6.88633 5.3125C6.88633 4.86895 7.2459 4.50937 7.68945 4.50937V2.99063C6.40712 2.99063 5.36758 4.03016 5.36758 5.3125H6.88633ZM7.68945 4.50937H12.0534V2.99063H7.68945V4.50937ZM12.0534 4.50937C12.0592 4.50937 12.0648 4.51171 12.0688 4.51574L13.1428 3.4419C12.8538 3.15292 12.4619 2.99063 12.0534 2.99063V4.50937ZM12.0688 4.51578L16.2987 8.74564L17.3726 7.67172L13.1427 3.44186L12.0688 4.51578ZM16.2986 8.7456C16.3028 8.74976 16.3051 8.75538 16.3051 8.7611H17.8238C17.8238 8.35243 17.6614 7.96061 17.3726 7.67176L16.2986 8.7456ZM16.3051 8.7611V16.25H17.8238V8.7611H16.3051ZM16.3051 16.25C16.3051 16.6936 15.9455 17.0531 15.502 17.0531V18.5719C16.7843 18.5719 17.8238 17.5324 17.8238 16.25H16.3051Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FileIcon;
